@import "styles/main.module";

.wrap {
  @include size(100%);
  @include flex(center, center);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.mask {
  @include size(100%);
  position: absolute;
  background-color: $black;
  opacity: 0.2;
  animation: fadeIn 0.2s ease-out;
}

.popup {
  width: 400px;
  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 24px -2px rgba(18, 22, 33, 0.2);
  z-index: 100;
  animation: bounceIn 0.2s ease-out;
}

.title {
  @include flex(space-between, center);
  min-height: 60px;
  padding: 0 25px;
  font-size: 1.4rem;
  column-gap: 20px;

  .closeIcon {
    @include size(40px);
    @include flex(center, center);
    min-width: 40px;
    margin-left: auto;
    border-radius: 50%;
    transition: 0.15s ease;
    transform: translate(10px, 0);
    cursor: pointer;

    svg {
      @include size(24px);
      color: #70757a;
    }

    &:hover {
      background-color: #f8f9fa;

      svg {
        color: #202124;
      }
    }
  }
}

.content {
  @extend %scrollbar;
  min-height: 40px;
  padding: 0 25px;
  font-size: 1rem;
  overflow: auto;
}

.actions {
  @include flex(flex-end, center);
  padding: 25px;
  column-gap: 16px;
}

.buttonLine,
.buttonFill {
  width: 80px;
  padding: 6px 24px;
  border: none;
  background-color: unset;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0.25px;
  box-shadow: 0 2px 0 rgba($black, 0.02);
  transition: 0.2s ease;
  cursor: pointer;
}

.buttonLine {
  background-color: $white;
  color: $blue6;
  border: 1px solid #dfdfdf;

  &:hover {
    background-color: #fafafa;
  }
}

.buttonFill {
  background-color: $blue6;
  color: $white;

  &:hover {
    background-color: rgba($blue6, 0.85);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.05);
  }

  80% {
    opacity: 1;
    transform: scale(0.89);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}
