@import 'styles/main.module';

.wrap {
    @include size(100%, 100vh);
    @include flex(center, center);
    background-color: #f0f2f5;

}

.content {
    padding: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    background-color: $white;
    border-radius: 2px;
}

.loginWrap {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.hr {
    @include size(100%, 1px);
    background-color: $black;

    &::before {
        content: "OR";
        position: absolute;

    }
}

.googleWrap {
    position: relative;
    height: 44px;
}