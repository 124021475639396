@import 'styles/main.module';


.wrap {
  @include size(100%);
  @include flex(center, center);
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($white, .6);
  animation: fadeIn .1s ease forwards;
  z-index: 9999;

  &.hide {
    animation: fadeOut .1s ease forwards;
  }
}

.loaderWrapper {
  @include size(200px);
  position: relative;
}

.loader {
  @include size(100%);
  animation: rotate 2s linear infinite;
  transform-origin: center center;
}

.circle {
  stroke-dasharray: 150, 200;
  stroke-dashoffset: -10;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: $blue6;
  font-weight: 700;
  letter-spacing: .25px;
  text-transform: capitalize;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}