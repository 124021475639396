@import "styles/main.module";

$menuColor: rgba($white, 0.65);
$menuBackground: #001529;
$background: #f0f2f5;

.wrap {
  @include size(100%);
  min-height: 100vh;
  background-color: $background;
  color: $gray8;
  position: fixed;
  display: grid;
  grid-template-rows: min-content 1fr;

  &.vertical {
    grid-template-columns: auto 1fr;
  }

  // a {
  //   transition: unset;
  // }

  @include breakpoint(tablet) {
    position: relative;
  }
}

.hiddenMenu,
.menuWrapper,
.account {
  height: inherit;
  position: relative;

  &:hover {
    .options {
      visibility: visible;
      opacity: 1;
    }
  }
}

.options:hover {
  visibility: visible;
  opacity: 1;
}

.content {
  max-width: 100vw;
  // position: relative;
  overflow: auto;

  @include breakpoint(tablet) {
    overflow: unset;
  }
}

.hiddenMenu {
  @include flex(center, center);
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #08c;
  }

  &::before {
    @include size(1px, 100%);
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-color: rgba($white, 0.3);
  }
}

.hiddenMenu .menuText {
  @include size(3px !important);
  padding: 0 !important;
  margin: 0 12px;
  background-color: $white !important;
  border-radius: 50%;

  &::before,
  &::after {
    @include size(100%);
    content: "";
    position: absolute;
    background-color: $white;
    border-radius: 50%;
  }

  &::before {
    top: -6px;
  }

  &::after {
    bottom: -6px;
  }
}

.hiddenMenu .options {
  right: 0;
  top: 100%;
}

.hiddenMenu .option,
.menuWrapper .option {
  position: relative;

  &::before {
    @include size(6px);
    content: "";
    border: 2px solid $gray4;
    transform: rotate(45deg);
    transition: 0.5s transform;
  }

  &:hover::before {
    border-color: $blue5;
    transform: rotate(225deg);
  }
}

.option {
  padding: 5px 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  color: #3a3a3a;
  cursor: pointer;

  &:hover {
    color: $blue5;
    visibility: visible;
  }

  &.active {
    background-color: $blue1;
  }
}

.headerWrapper {
  position: sticky;
  top: 0;
  box-shadow: 0px 1px 0px 0px #dfdfdf;
  z-index: 5;

  &.vertical {
    grid-column: unset;
  }

  .hiddenMenu:hover,
  .menuWrapper:hover,
  .account:hover {
    .menuText {
      color: $white;
      background-color: #08c;
    }

    .options {
      transform: translate(0, 8px);
    }
  }

  .menuText.active {
    color: $white;
    background-color: #08c;
  }

  .account .options > .option:last-child {
    margin-top: 8px;
    padding-top: 13px;
    border-top: 1px solid #0000000f;
  }

  .menuText {
    height: inherit;
    padding: 0 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    white-space: nowrap;
    cursor: pointer;
  }

  .options {
    min-width: 100%;
    padding: 8px 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 4px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0 0px 8px 0px rgba(#121621, 0.2);
    transition: transform 0.2s ease, opacity 0.2s ease;
    z-index: 1;

    &::before {
      @include size(100%, 8px);
      content: "";
      position: absolute;
      top: -8px;
    }
  }
}

.headerWrapper .header {
  height: 36px;
  padding: 0 20px;
  display: flex;
  background-color: $white;

  .account {
    width: fit-content;
    margin-left: auto;

    .options {
      right: 0;
    }
  }
}

.hiddenbar {
  width: 100%;
  position: absolute;
  overflow: hidden;
  visibility: hidden;
}

.hiddenbar,
.menubar {
  height: 32px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  background-color: $menuBackground;
  flex-wrap: wrap;

  .menuText {
    position: relative;
    color: $menuColor;
  }

  & > .menuWrapper:not(:nth-child(1)) > .menuText::before {
    @include size(1px, 100%);
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-color: rgba($white, 0.3);
  }
}

.sidebar {
  @include size(200px, 100vh);
  padding: 42px 0 0 16px;
  position: relative;
  color: $menuColor;
  background-color: $menuBackground;
  grid-row: span 2;
  transition: width 0.4s ease, padding 0.2s ease;
  user-select: none;
  z-index: 6;

  &.collapse {
    width: 50px;
    padding: 42px 0 0 12px;

    .menuText {
      padding: 5px 0 5px 8px;
    }

    .text {
      display: none;
    }
  }

  .menuWrapper {
    position: relative;

    &:hover {
      z-index: 1;

      .menuText {
        color: rgba($black, 0.85);
        background-color: $background;

        &::before,
        &::after {
          visibility: visible;
        }
      }

      .options {
        transform: unset;
      }
    }
  }

  .menuText {
    min-height: 32px;
    padding: 5px 0 5px 16px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 500;
    border-radius: 20px 0 0 20px;
    color: $menuColor;
    cursor: pointer;

    &::before,
    &::after {
      @include size(16px, 40px);
      content: "";
      position: absolute;
      right: 0;
      background-color: transparent;
    }

    &::before {
      top: -40px;
      border-bottom-right-radius: 20px;
      box-shadow: 0 16px 0 0 $background;
      visibility: hidden;
    }

    &::after {
      bottom: -40px;
      border-top-right-radius: 20px;
      box-shadow: 0 -16px 0 0 $background;
      visibility: hidden;
    }

    &:hover,
    &.active {
      color: rgba($black, 0.85);
      background-color: $background;

      &::before,
      &::after {
        visibility: visible;
      }
    }

    svg {
      min-width: 14px;
    }
  }

  .options {
    min-width: 160px;
    margin-left: 10px;
    padding: 8px 0;
    position: absolute;
    top: 0;
    left: 100%;
    display: flex;
    flex-direction: column;
    color: rgba($black, 0.85);
    background-color: $white;
    border-radius: 4px;
    white-space: nowrap;
    visibility: hidden;
    // opacity: 0;
    // transform: translate(-8px, 0);
    // transition: .2s ease;
    box-shadow: 0 0px 8px 0px rgba(#121621, 0.2);
    z-index: 2;

    &::before {
      @include size(10px, 100%);
      content: "";
      position: absolute;
      top: 0;
      left: -10px;
    }
  }

  .collapseIcon {
    @include size(100%, 42px);
    @include flex(flex-end, center);
    padding-right: 16px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    svg {
      @include size(18px);
    }
  }
}

.mbMenuIcon {
  @include size(36px);
  @include flex(center, center);

  svg {
    @include size(20px);
  }
}

.mbMenuWrapper {
  @include size(100%, 100vh);
  max-width: 300px;
  position: fixed;
  left: 0;
  top: 0;
  color: $menuColor;
  background-color: $menuBackground;
  animation: slideInMenu 0.4s ease forwards;
  z-index: 5;

  .header {
    @include size(100%, 60px);
    padding-left: 32px;
    display: flex;
    align-items: center;
    gap: 32px;
    font-weight: 500;
  }

  .mbMenu {
    padding-top: 16px;
    box-shadow: 0 -1px 0 0 rgba($white, 0.3);
  }

  .menuText {
    padding: 8px 32px;
    display: flex;
    align-items: center;
    gap: 16px;
    font-weight: 500;

    .text + svg {
      @include size(20px);
      margin-left: auto;
    }

    &.active {
      background-color: #08c;
      color: $white;
    }
  }
}

.mbMenuMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.2);
  animation: fadeInMask 0.4s ease forwards;
  z-index: 5;

  &.collapse {
    animation: fadeOutMask 0.4s ease forwards;

    & + .mbMenuWrapper {
      animation: slideOutMenu 0.4s ease forwards;
    }
  }
}

@keyframes fadeInMask {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOutMask {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slideInMenu {
  0% {
    left: -300px;
  }

  100% {
    left: 0;
  }
}

@keyframes slideOutMenu {
  0% {
    left: 0;
  }

  100% {
    left: -300px;
  }
}

body {
  :global {
    .rc-virtual-list-holder-inner {
      position: unset !important;
    }

    .ant-select-dropdown {
      width: max-content !important;
    }

    .ant-picker-panel-container,
    .ant-picker-footer {
      margin-left: 0 !important;
      //width: 280px !important;
    }

    // .ant-picker-panels>*:first-child button.ant-picker-header-super-next-btn {
    //   visibility: visible !important;
    // }

    // .ant-picker-panel-container,
    // .ant-picker-footer {
    //   margin-left: 0 !important;
    //   width: 280px !important;
    // }
  }
}

:global {
  // popup background color
  .tox-dialog-wrap__backdrop {
    background-color: rgba($black, 0.2) !important;
  }

  // remove tinymice popup error message
  .tox-notifications-container {
    display: none;
  }
}

.serverTimer {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;

  .block {
    display: flex;
    column-gap: 10px;
    white-space: nowrap;
  }
}

.deniedWrap {
  @include size(100%);
  @include flex(center, center);
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f0f2f5;
  z-index: 4;

  .deniedContent {
    text-align: center;

    h1 {
      font-size: 24px;
      color: #000000d9;
    }

    p {
      font-size: 14px;
      color: #00000073;
    }
  }
}
