@import 'styles/main.module';



.wrap {
  @include size(100%);
  @include flex(center, center);
  position: fixed;
  background-color: rgba($black, 0.4);
  top: 0;
  z-index: 999;
}

.modal {
  @include flex(center, center);
  padding: 40px;
  flex-direction: column;
  background-color: $white;
  row-gap: 25px;
  border-radius: 4px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;

  form {
    margin-bottom: 24px;
  }
}

.loginWrap {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

