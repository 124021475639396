/* ----------------------------------------
  Color
---------------------------------------- */

$link: #4f3cc9;

$white: #ffffff;
$black: #000000;

$darkBlack: #001529;
$darkWhite: rgba(255, 255, 255, 0.65);
$background: #f0f2f5;
$primary: #0275d8;
$blue: #0088cc;

$red: #df0001;
$pink: #e08f95;

$orange: #f5803e;
$yellow: #fbbb22;
$green: #72c279;

$turquoise: #36beb3;

$orange1: #fff7e6;
$orange2: #ffe7ba;

$gold6: #fadb14;

$blue1: #e6f7ff;
$blue2: #bae7ff;
$blue3: #91d5ff;
$blue4: #69c0ff;
$blue5: #40a9ff;
$blue6: #1890ff;
$blue7: #096dd9;
$blue8: #0050b3;
$blue9: #003a8c;

$gray1: #f2f2f2;
$gray2: #dfdfdf;
$gray3: #cccccc;
$gray4: #a6a6a6;
$gray5: #808080;
$gray6: #737373;
$gray7: #4d4d4d;
$gray8: #3a3a3a;
$gray9: #262626;

/* ----------------------------------------
  Breakpoints
---------------------------------------- */

$breakpointPhoneSmall: 320px;
$breakpointPhone: 400px;
$breakpointPhoneWide: 480px;
$breakpointPhablet: 560px;
$breakpointTabletSmall: 640px;
$breakpointTablet: 790px;
$breakpointTabletWide: 1060px;
$breakpointDesktop: 1260px;
$breakpointDesktopWide: 1460px;

$maskColor: rgba($black, 0.45);
$boxShadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
  0 9px 28px 8px #0000000d;
