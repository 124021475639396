@import 'styles/main.module';


$error: #f5222d;
$success: #52c41a;
$shadow: rgba(18, 22, 33, 0.1);


.mask {
  @include size(100%);
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  animation: fadeInOut ease-out forwards;
  z-index: 9999;
}

.wrap {
  @include size(fit-content, 200px);
  @include flex(center, center);
  min-width: 300px;
  padding: 20px;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  gap: 20px;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 4px 24px -2px $shadow;
  transform-origin: 0 0;
  text-align: center;
  animation: bounceInOut ease-out forwards;
  z-index: 9999;

  span {
    color: $gray6;
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.error {
  @include size(80px);
  position: relative;
  border: 3px solid rgba($error, .2);
  border-radius: 50%;

  &:before,
  &:after {
    @include size(6px, 40px);
    content: '';
    background: $error;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 5px;
  }

  &:before {
    opacity: 0;
    transform: scale(.3) translate(-50%, -50%) rotate(45deg);
    animation: bounceInBefore .3s linear forwards .3s;
  }

  &:after {
    opacity: 0;
    transform: scale(.3) translate(-50%, -50%) rotate(-45deg);
    animation: bounceInAfter .3s linear forwards .3s;
  }

  svg {
    @include size(80px);
    fill: none;
    stroke-width: 3;
    stroke: $error;
    stroke-linecap: round;
    stroke-linejoin: round;
    display: block;
    position: absolute;
    left: -3px;
    top: -3px;
    right: -3px;
    bottom: -3px;
    z-index: 1;
    stroke-dashoffset: 162.6 - 38;
    stroke-dasharray: 0 162.6 133 (162.6 - 133);
    transition: all .4s ease .2s;
  }
}

.success {
  @include size(80px);
  border: 5px solid rgba($success, .2);
  border-radius: 50%;
  position: relative;
  animation: checkMarkAnim .3s linear forwards .1s;

  svg {
    @include size(80px);
    fill: none;
    stroke-width: 3;
    stroke: $success;
    stroke-linecap: round;
    stroke-linejoin: round;
    display: block;
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 1;
  }
}

@keyframes bounceInBefore {
  0% {
    opacity: 0;
    transform: scale(.3) translate(-50%, -50%) rotate(45deg);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(45deg);
  }

  80% {
    opacity: 1;
    transform: scale(.89) translate(-50%, -50%) rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes bounceInAfter {
  0% {
    opacity: 0;
    transform: scale(.3) translate(-50%, -50%) rotate(-45deg);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(-45deg);
  }

  80% {
    opacity: 1;
    transform: scale(.89) translate(-50%, -50%) rotate(-45deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes checkMarkAnim {
  0% {
    stroke-dashoffset: 162.6 - 38;
    stroke-dasharray: 0 162.6 133 (162.6 - 133);
  }

  100% {
    stroke-dashoffset: 162.6;
    stroke-dasharray: 0 162.6 28 (162.6 - 28);
  }
}

@keyframes bounceInOut {
  0% {
    opacity: 0;
    transform: scale(.95) translate3d(-50%, -50%, 0);
  }

  10% {
    opacity: 1;
    transform: scale(1) translate3d(-50%, -50%, 0);
  }

  90% {
    opacity: 1;
    transform: scale(1) translate3d(-50%, -50%, 0);
  }

  100% {
    opacity: 0;
    transform: scale(.95) translate3d(-50%, -50%, 0);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: .2;
  }

  90% {
    opacity: .2;
  }

  100% {
    opacity: 0;
  }
}